import { TypeAnimation } from 'react-type-animation'
export const App = () => {
	return (
		<div className='container fc cc fgap'>
			<div className='container fc ec'>
				<TypeAnimation
					sequence={
						[
							'3ERA: web3 research',
							2000,
							'3ERA: distributed computing',
							2000,
							'3ERA: decentralized finance',
							2000,
							'3ERA: smart contracts',
							2000,
							'3ERA: digital assets',
							2000,
							'3ERA: on-chain analytics',
							2000,
							'3ERA: layer 2 scaling',
							2000,
							'3ERA: consensus algorithms',
							2000
						]
					}
					repeat={Infinity}
				/>
			</div>
			<div id='contact' className='container fc sc'>
				hello@3era.xyz
			</div>
		</div>
	)
}